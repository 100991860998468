<template>
  <base-loading v-if="loading" />
  <div
    v-else
    style="padding: 10px;"
  >
    <div
      class="font-weight-bold"
      style="font-size: 28px; color: #183B94; margin-bottom: 10px"
    >
      Meu carrinho
    </div>
    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="8"
        xl="8"
        class="py-0"
      >
        <v-card
          v-if="$vuetify.breakpoint.smAndUp"
          class="my-3 py-2"
          elevation="1"
          style="border-radius: 8px;"
        >
          <v-card-title>
            <h2
              style="font-size: 18px; font-weight: 700;"
            >
              Produtos
            </h2>
          </v-card-title>

          <v-checkbox
            v-if="this.$store.state.user.roles[0].slug === 'tecnico'"
            v-model="checkbox"
            class="ml-5"
            label="Aplicar as condições de campanha em produtos que não atingiram as quantidades mínimas estabelecidas."
          />

          <div
            style="min-height: 570px; padding: 16px"
          >
            <card-carrinho
              v-for="item in produtos"
              :key="item.id"
              class="mb-5"
              :produto="item"
            />
          </div>
        </v-card>
        <div v-else>
          <div
            style="min-height: 100px;"
          >
            <card-carrinho
              v-for="item in produtos"
              :key="item.id"
              class="mb-5"
              :produto="item"
            />
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
        lg="4"
        xl="4"
      >
        <v-card
          class="mt-0 mb-12"
          elevation="1"
          style="border-radius: 8px; height: 623px;"
        >
          <v-card-title class="pl-6">
            <h2 style="font-size: 18px; font-weight: 700; color: #183B94; ">
              Detalhes do pagamento
            </h2>
          </v-card-title>
          <v-card-text>
            <div
              style="min-height: 300px; margin-top: 20px;"
            >
              <v-row
                v-if="descontoTotal > 0"
                class="py-0"
                style="margin-top: 2px;"
              >
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  align-self="center"
                  class="pt-2 pb-1"
                  style=""
                >
                  <div
                    class="ml-2"
                    style="text-align: left; font-size: 14px; font-weight: 400;"
                  >
                    Subtotal dos produtos:
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="pt-2 pb-1"
                >
                  <div
                    class="mr-2"
                    style="text-align: right; font-size: 14px; font-weight: 400; text-decoration: line-through;"
                  >
                    R$ {{ currencyFormatter( precoSubTotal ) }}
                  </div>
                </v-col>
              </v-row>
              <v-row
                v-if="descontoTotal > 0"
                style="margin-bottom: 2px;"
              >
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-1"
                  style="border: 0px;"
                >
                  <div
                    class="ml-2"
                    style="text-align: left; font-size: 14px; font-weight: 400;"
                  >
                    Descontos:
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-1"
                >
                  <div
                    class="mr-2"
                    style="text-align: right; font-size: 14px; font-weight: 700;"
                  >
                    R$ {{ currencyFormatter(descontoTotal) }}
                  </div>
                </v-col>
              </v-row>

              <v-row
                class="pb-0"
                style="margin-bottom: 2px; margin-top: 2px;"
              >
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="5"
                  xl="6"
                  style="border: 0px;
                    color: rgb(51, 51, 51);"
                  class="pt-1 pb-2"
                >
                  <div
                    class="ml-2"
                    style="text-align: left; font-size: 14px; font-weight: 900;"
                  >
                    Total:
                  </div>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  lg="7"
                  xl="6"
                  style="border: 0px; color: rgb(51, 51, 51);"
                  class="pt-1 pb-0"
                >
                  <div
                    class="mr-2"
                    style="text-align: right; font-size: 14px; font-weight: 900;"
                  >
                    R$ {{ currencyFormatter( precoTotal ) }}
                  </div>
                </v-col>
              </v-row>

              <v-row v-if="prazoEntrega > 0">
                <v-col
                  cols="12"
                >
                  <div
                    style="font-size: 12px !important; color: #808080;"
                    class="mb-2 text-subtitle-1"
                  >
                    <v-icon
                      color="#B94F4F"
                    >
                      mdi-truck-fast
                    </v-icon>
                    Previsão de Entrega: {{ prazoEntrega }} dias úteis.
                  </div>
                  <div
                    style="font-size: 10px !important; color: #B94F4F; font-weight: bold;"
                    class="mb-2 text-subtitle-1"
                  >
                    "O valor do pedido poderá variar de acordo com o frete da entrega."
                  </div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  cols="12"
                >
                  <div
                    style="font-size: 10px !important; color: #B94F4F; font-weight: bold;"
                    class="mb-2 text-subtitle-1"
                  >
                    <v-icon
                      color="#B94F4F"
                    >
                      mdi-truck-fast
                    </v-icon>
                    "O valor do pedido poderá variar de acordo com o Frete da entrega."
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions style="margin-top: 150px;">
            <v-row dense>
              <v-col
                cols="12"
              >
                <v-btn
                  color="success"
                  height="35px"
                  small
                  block
                  class="text-none text-white"
                  style="border-radius: 8px;"
                  @click="handleFinalizarPedidoButton()"
                >
                  Prosseguir com Pedido
                  <v-icon
                    small
                    color="white"
                    class="ml-1"
                  >
                    mdi-clipboard-file-outline
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
              >
                <v-btn
                  color="primary"
                  height="35px"
                  small
                  block
                  class="text-none text-white"
                  style="border-radius: 8px;"
                  @click="handleBackButton()"
                >
                  Continuar Comprando
                  <v-icon
                    small
                    color="white"
                    class="ml-1"
                  >
                    mdi-backup-restore
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import CarrinhoStore from '@/store/modules/carrinho'
  import { mapState, mapGetters, mapActions } from 'vuex'
  import CardCarrinho from './_cardCarrinhoPagina.vue'
  import { currencyFormatter } from '../../utils/formatter'
  import Swal from 'sweetalert2'

  export default {
    components: {
      CardCarrinho,
    },
    data: () => ({
      currencyFormatter,
      checkbox: false,
    }),
    computed: {
      ...mapState('loja', ['loading']),
      ...mapState('carrinho', ['produtos']),
      ...mapGetters('carrinho', ['precoTotal', 'precoSubTotal', 'descontoTotal', 'prazoEntrega']),
    },
    watch: {
      'produtos' () {
        if (this.produtos.length <= 0) {
          this.$router.push({ path: `/${this.$user.get().role}/loja` })
        }
      },
    },
    async created () {
      if (!this.$store.hasModule('carrinho')) { this.$store.registerModule('carrinho', CarrinhoStore) }
      if (this.$store.getters['loja/catalogoFiltered'].length === 0) {
        this.$store.dispatch('loja/BOOTSTRAP')
      }
    },
    methods: {
      ...mapActions('carrinho', ['BOOTSTRAP']),
      handleBackButton () {
        this.$router.push({ path: `/${this.$user.get().role}/loja/produtos` })
      },
      handleFinalizarPedidoButton () {
        const itens = this.produtos.filter(el => el.disponibilidade !== 2)
        let parcelaMinima = 0

        itens.sort(function (a, b) {
          return parseFloat(b.vlr_parcelas) - parseFloat(a.vlr_parcelas)
        })

        if (itens.length > 0 && parseFloat(itens[0].vlr_parcelas) !== 0) {
          parcelaMinima = itens[0].vlr_parcelas
        }

        if (parseFloat(parcelaMinima) > 0 && parseFloat(parcelaMinima) > parseFloat(this.precoTotal)) {
          Swal.fire({
            icon: 'warning',
            title: 'O Valor do Pedido é menor que a parcela mínima!',
            text: 'A Parcela Mínima permitida é de R$ ' + currencyFormatter(parcelaMinima) + '.',
          })
        } else {
          this.$router.push({ path: `/${this.$user.get().role}/loja/pedido` })
        }
      },
    },
  }
</script>
